
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IssueTypeDetail } from '@/types/inspection'
import { ElForm } from 'element-ui/types/form'
@Component({
  name: 'QuestionType'
})
export default class QuestionType extends Vue {
    @Prop({ default: false }) private isShowType!: boolean
    private isLoadType = false
    private isUpdate = false
    private typeList: Array<IssueTypeDetail> = []
    private typeSecondLevel: Array<IssueTypeDetail> = [] // 二级节点类型列表
    private isHasChildNode = false // 是否有子节点
    private props = {
      children: 'childNode',
      label: 'issueTypeName'
    }

    private showFormDialog = false
    private info: IssueTypeDetail = {
      issueTypeName: '',
      issueTypeId: ''
    }

    private props1 = {
      label: 'issueTypeName',
      children: 'childNode',
      value: 'issueTypeId'
    }

    private rules = {
      issueTypeName: [
        { required: true, message: '请输入问题类型，不超过20字', whitespace: true }
      ]
    }

    created () {
      this.getTypeList()
    }

    // 查询类型列表
    getTypeList () {
      this.$axios.get<IssueTypeDetail[]>(this.$apis.inspection.selectIssueTypeByList).then(res => {
        this.typeList = res || []
      })
    }

    // 查询二级类型
    getIssueTypeList () {
      this.$axios.get<IssueTypeDetail[]>(this.$apis.inspection.selectIssueTypeList, { typeId: this.info.issueTypeId }).then(res => {
        this.typeSecondLevel = res || []
      })
    }

    addType () {
      this.showFormDialog = true
      this.isHasChildNode = false
      this.getIssueTypeList()
    }

    deleteType (row: IssueTypeDetail) {
      this.$confirm(`是否删除问题类型【${row.issueTypeName}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.inspection.deleteIssueType,
          {
            issueTypeId: row.issueTypeId
          }).then(() => {
          this.$message.success('删除成功')
          this.getTypeList()
        })
      })
    }

    editType (row: IssueTypeDetail) {
      this.showFormDialog = true
      this.isUpdate = true
      this.isHasChildNode = false
      this.info = JSON.parse(JSON.stringify(row))
      this.getIssueTypeList()
      if (this.info.childNode) {
        this.isHasChildNode = true
        delete this.info.childNode
      }
      // 一级菜单
      if (!this.info.parentIssueTypeId) {
        delete this.info.parentIssueTypeId
      }
    }

    closeTypeConfig () {
      this.$emit('update:isShowType', false)
    }

    closeFormDialog () {
      this.info.issueTypeName = ''
      this.info.issueTypeId = ''
      // 非第一层级菜单
      if (this.info.parentIssueTypeId) {
        this.info.parentIssueTypeId = ''
      }
      this.isUpdate = false
    }

    submit () {
      (this.$refs.info as ElForm).validate((valid) => {
        if (valid) {
          this.isLoadType = true
          this.$axios.post(this.isUpdate ? this.$apis.inspection.updateIssueType : this.$apis.inspection.insertIssueType,
            this.isUpdate ? this.info : { parentIssueTypeId: this.info.parentIssueTypeId, issueTypeName: this.info.issueTypeName }).then(() => {
            this.getTypeList()
            this.showFormDialog = false
          }).finally(() => {
            this.isLoadType = false
          })
        }
      })
    }
}
