
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { InspectionDetail } from '@/types/inspection'
import QuestionType from '@/views/inspection/QuestionType.vue'

@Component({
  name: 'List',
  components: { QuestionType }
})
export default class List extends Vue {
  private searchInfo: { projectName: string; patrolUserId: string; dateRange: Array<string> } = {
    projectName: '',
    patrolUserId: '',
    dateRange: []
  }

  private loading = false
  // 获取巡查人员列表
  private patrolUserList = []
  private tableData: Array<InspectionDetail> = []
  private page = 1
  private size = 10
  private total = 0

  private isShowAdd = false
  private projectPatrolUserList = []
  private addForm: { projectId: string; patrolDate: string; patrolUserId: string } = {
    projectId: '',
    patrolDate: '',
    patrolUserId: ''
  }

  private addRules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    patrolDate: [
      { required: true, message: '请选择巡查日期', trigger: 'change' }
    ],
    patrolUserId: [
      { required: true, message: '请选择巡查人员', trigger: 'change' }
    ]
  }

  private isShowSet = false
  private setObject: 'person' | 'node' = 'person'
  private roleList: Array<{ role: string; roleName: string }> = []
  private setForm: { patrolRoles: string; rectifierRoles: string; auditorRoles: string } = {
    patrolRoles: '',
    rectifierRoles: '',
    auditorRoles: ''
  }

  private setRules = {
    patrolRoles: [
      { required: true, message: '请选择指派巡查人角色', trigger: 'change' }
    ],
    rectifierRoles: [
      { required: true, message: '请选择指派整改人角色', trigger: 'change' }
    ],
    auditorRoles: [
      { required: true, message: '请选择指派审核人角色', trigger: 'change' }
    ]
  }

  // 开关状态 0关闭 1开启
  private issueConfigStatus = '0'
  private needConfirmCount = 0
  // 问题类型配置
  private isShowType = false

  get initTypeForm () {
    return {
      issueTypeId: '',
      issueTypeName: ''
    }
  }

  get projectList () {
    return this.$store.state.projectList
  }

  $refs!: {
    addForm: ElForm;
    setForm: ElForm;
    typeTable: any;
  }

  created (): void {
    this.getIssueConfig()
    this.getList()
    this.getPatrolUserList()
    this.getRoleList()
  }

  // 查询巡查问题节点配置和待确认问题总数 issueConfigStatus 开关状态 0关闭 1开启
  getIssueConfig () {
    this.$axios.get(this.$apis.inspection.selectIssueConfig).then(res => {
      this.issueConfigStatus = res.issueConfigStatus
      this.needConfirmCount = res.needConfirmCount || 0
    })
  }

  getPatrolUserList () {
    this.$axios.get(this.$apis.inspection.selectUserByProcessNodeCodeByList, {
      processCode: '1', // 写死，代表巡查
      processNodeCode: '0'
    }).then(res => {
      this.patrolUserList = res.list
    })
  }

  getRoleList () {
    this.$axios.get(this.$apis.common.getRoleList, {}).then(res => {
      this.roleList = res
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<InspectionDetail> }>(this.$apis.inspection.selectPatrolSummary,
      {
        projectName: this.searchInfo.projectName,
        patrolUserId: this.searchInfo.patrolUserId,
        startDate: this.searchInfo.dateRange && this.searchInfo.dateRange[0] ? this.searchInfo.dateRange[0] : '',
        endDate: this.searchInfo.dateRange && this.searchInfo.dateRange[1] ? this.searchInfo.dateRange[1] : '',
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 关闭添加弹框
  closeAdd () {
    this.addForm = {
      projectId: '',
      patrolDate: '',
      patrolUserId: ''
    }
    this.$refs.addForm.clearValidate()
    this.isShowAdd = false
  }

  // 添加巡查
  addInspection () {
    this.$refs.addForm.validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.inspection.insertPatrol, this.addForm).then(() => {
          this.$message.success('添加成功')
          this.onSearch()
          this.closeAdd()
        })
      }
    })
  }

  getProjectPatrolUserList (val: string) {
    this.$axios.get(this.$apis.inspection.selectUserByProcessNodeCodeByList, {
      processNodeCode: '0',
      processCode: '1', // 写死，代表巡查
      projectId: val
    }).then(res => {
      this.projectPatrolUserList = res.list || []
    })
  }

  // 获取人员设置信息
  getPatrolProcess () {
    this.$axios.get(this.$apis.inspection.selectPatrolProcess).then((res) => {
      this.setForm = res
    })
  }

  // 设置巡查
  setInspection () {
    this.isShowSet = true
    this.setObject = 'person'
    this.getPatrolProcess()
  }

  // 人员设置提交
  commitSetInspection () {
    this.$refs.setForm.validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.inspection.updatePatrolProcess, this.setForm).then(() => {
          this.$message.success('设置成功')
          this.isShowSet = false
        })
      }
    })
  }

  // 更新节点配置
  updateIssueConfig (value: string) {
    this.$axios.post(this.$apis.inspection.updateIssueConfig, {
      issueConfigStatus: value
    }).then(() => {
      if (value === '1') {
        this.$message.success('节点开启成功')
        this.getIssueConfig()
        this.getList()
      } else {
        this.$message.success('节点关闭成功')
      }
    })
  }

  toIssueList (row: InspectionDetail) {
    this.$router.push({
      name: 'issueList',
      params: { patrolId: row.patrolId }
    })
  }

  // 巡查删除
  delInspection (row: InspectionDetail) {
    if (Number(row.issueAmount) > 0) {
      this.$message.error('已存在巡查问题，不能删除')
    } else {
      this.$confirm('确定要删除该巡查吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.inspection.deletePatrol,
          {
            patrolId: row.patrolId
          }).then(() => {
          this.$message.success('删除成功')
          this.onSearch()
        })
      })
    }
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    const info = this.searchInfo.dateRange.length ? {
      projectName: this.searchInfo.projectName,
      patrolUserId: this.searchInfo.patrolUserId,
      startDate: this.searchInfo.dateRange[0],
      endDate: this.searchInfo.dateRange[1]
    } : {
      projectName: this.searchInfo.projectName,
      patrolUserId: this.searchInfo.patrolUserId
    }
    this.$axios.downloadFile(this.$apis.inspection.exportPatrolData, info).finally(() => {
      loading.close()
    })
  }
}
